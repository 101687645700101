import { exchange } from '@/libs/blockchain/config'

export default async function sellTokens(amountUSDT, amountYT) {
    const buyTokensTx = await exchange.sellTokens((amountUSDT * 10 ** 18).toString(), (amountYT * 10 ** 18).toString()).then().catch(error => {
        const err = {
            type: 'error',
            text: error.toString(),
        }
        return err
    })
    return buyTokensTx
}
