import Vue from 'vue'
import axios from 'axios'

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: process.env.API_URL + '/api/v1',
    // timeout: 1000,
    withCredentials: true,
    // headers: { 'Content-Type': 'multipart/form-data' },
})

axiosIns.interceptors.response.use(response => response, error => {
    if (error.response.status === 401) {
        localStorage.clear()
        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
    }
    return error
})

Vue.prototype.$http = axiosIns

export default axiosIns
