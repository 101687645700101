import sessionDefaultConfig from './sessionDefaultConfig'

export default class SessionService {
    // Will be used by this service for making API calls
    axiosIns = null

  sessionConfig = { ...sessionDefaultConfig }

  constructor(axiosIns) {
      this.axiosIns = axiosIns
  }

  async login(endpoint, args) {
      const body = args
      body.firebase_token = 'debug'
      const response = await this.axiosIns.post(endpoint, body)

      if (response.status !== 200 || response.data.statusCode === 500) {
          if (response.data.statusCode === 500) {
              throw new Error(response.data.error)
          } else {
              throw new Error(response.statusText)
          }
      }

      const {
          general, kyc, notification, wallets, ability, extras, two_fa, auth_methods,
      } = response.data

      const user = {
          id: general.id,
          avatar: general.avatar ?? '',
          name: general.name ?? '',
          username: general.username ?? '',
          email: general.email,
          role: general.role,
          status: kyc.status,

          general,
          kyc,
          notification,
          wallets,

          ability,
          extras,
          two_fa,
          authMethods: auth_methods,
      }
      localStorage.setItem('userData', JSON.stringify(user))
      return user
  }

  async register(endpoint, args) {
      const body = args

      body.firebase_token = 'debug'

      const response = await this.axiosIns.post(endpoint, body)
      if (response === undefined) {
          throw new Error('Что-то пошло не так!')
      }

      if (response.status !== 200 || response.data.statusCode === 500) {
          throw new Error(response.data.message)
      }

      const {
          general, kyc, notification, wallets, ability, extras, auth_methods,
      } = response.data
      const user = {
          id: general.id,
          avatar: general.avatar ?? '',
          name: general.name ?? '',
          username: general.username ?? '',
          email: general.email,
          role: general.role,
          status: kyc.status,

          general,
          kyc,
          notification,
          wallets,

          ability,
          extras,
          authMethods: auth_methods,
      }

      localStorage.setItem('userData', JSON.stringify(user))
      return user
  }
}
