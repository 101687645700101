import Vue from 'vue'
import Vuex from 'vuex'

// Vuexy
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app/app-config'
import verticalMenu from './app/vertical-menu'
// Modules
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        'app-ecommerce': ecommerceStoreModule,
        // modules
        user,
    },
    strict: process.env.DEV,
})
