export default [
    {
        path: 'acl',
        name: 'acl',
        component: () => import('@/views/ui-elements/extensions/acl/ACL.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
        },
    },
]
