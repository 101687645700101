export default [
    {
        path: 'not-authorized',
        name: 'not-authorized',
        component: () => import('@/views/finbridge/not-authorized/NotAuthorized.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
    },
]
