export default {
    // Endpoints
    loginEndpoint: '/auth/login',
    registerEndpoint: '/auth/register',

    ethEndpoint: '/auth',
    firebaseEndpoint: '/auth',

    logoutEndpoint: '/auth/logout',
}
