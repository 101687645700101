import blog from './blog'
import calendar from './calendar'
import chat from './chat'
import ecommerce from './ecommerce'
import email from './email'
import todo from './todo'
import invoice from './invoice'
import user from './user'

export default [
    ...blog,
    ...calendar,
    ...chat,
    ...ecommerce,
    ...email,
    ...todo,
    ...invoice,
    ...user,
]
