import axiosIns from '@/libs/axios'
import Blockchain from '@/libs/blockchain/blockchain'
import Firebase from '@/libs/firebase/firebase'

export default class SettingsAPI {
    static async google(succeessHandler, errorHandler, endPoint) {
        await Firebase.google(succeessHandler, errorHandler, endPoint)
    }

    static async apple(succeessHandler, errorHandler, endPoint) {
        await Firebase.apple(succeessHandler, errorHandler, endPoint)
    }

    static async metamask(succeessHandler, errorHandler, endPoint) {
        await Blockchain.login(succeessHandler, errorHandler, endPoint)
    }

    static async connectWallet(request) {
        const result = await Blockchain.getWallet(request.signingMessage)
        return result
    }

    static async block() {
        return await axiosIns.post(`/settings/block`).then(response => response.data).catch(error => error)
    }

    static async notification(request) {
        return await axiosIns.post(`/settings/notification`, request).then(response => response.data).catch(error => error)
    }

    static async password(request) {
        return await axiosIns.post(`/settings/password`, request).then(response => response.data).catch(error => error)
    }

    static async general(request) {
        return await axiosIns.post('/settings/general', request).then(response => response.data).catch(error => error)
    }
}
