export default [
    {
        path: 'privacy-policy',
        name: 'privacy-policy',
        component: () => import('@/views/finbridge/policy/PrivacyPolicy.vue'),
        meta: {
            pageTitle: 'Privacy policy',
            breadcrumb: [
                {
                    text: 'Pages',
                },
                {
                    text: 'Privacy policy',
                    active: true,
                },
            ],
        },
    },
    {
        path: 'terms-of-use',
        name: 'terms-of-use',
        component: () => import('@/views/finbridge/policy/TermsOfUse.vue'),
        meta: {
            pageTitle: 'Terms of use',
            breadcrumb: [
                {
                    text: 'Pages',
                },
                {
                    text: 'Privasy policy',
                    active: true,
                },
            ],
        },
    },
]
