import admin from './admin'
import auth from './auth'
import error from './error'
import marketplace from './marketplace'
import invoice from './invoice'
import kycRequest from './kyc-request'
import landing from './landing'
import learnCenter from './learn-center'
import notAuthorized from './not-authorized'
import policy from './policy'
import reports from './reports'
import settings from './settings'
import transactions from './transactions'

export default [...admin, ...auth, ...error, ...marketplace, ...invoice, ...kycRequest, ...landing, ...learnCenter, ...notAuthorized, ...policy, ...reports, ...settings, ...transactions]
