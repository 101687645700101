import {
    ethers, provider, GovernorContract, GovernanceToken,
} from '@/libs/blockchain/config'
// import User from '@/api/user'
import { voteStatus } from './functions'

export default async function vote(votingAddress, proposalId, sum, id, percent) {
    console.log(`Start 3 voting: ${votingAddress}`)
    // Работаем с контрактом и данным propose от votingAddress
    const signer = provider.getSigner()
    const governer = new ethers.Contract(GovernorContract.address, GovernorContract.abi, signer)

    // Проверка состояния Propose
    const state = await checkState(governer, proposalId)
    if (!state) {
        return state
    }

    if (await governer.hasVoted(proposalId, votingAddress)) {
        console.log(`You are voted: ${votingAddress}`)
        return {
            status: 'info',
            title: 'Info',
            message: 'You are voted!',
        }
    }
    console.log(`Start vote: ${votingAddress}`)
    // 0 = Against (Против), 1 = For (За), 2 = Abstain for this example (Воздержался)

    const voteTx = await governer
        .voteAndFreeze(proposalId, 1, GovernanceToken.address, (sum * 10 ** 18).toString(), id, (percent * 10 ** 18).toString())
        .catch(e => {
            console.log(e.body ? JSON.parse(e.body).error.message : e)
            return false
        })
    if (!voteTx) {
        return {
            status: 'error',
            title: 'Vote error',
            message: 'Your vote has been rejected',
        }
    }

    const voteTxReceipt = await voteTx.wait(1)
    console.log('Reason: ' + voteTxReceipt.events[0].args.reason)

    return {
        status: 'success',
        title: 'Success',
        message: 'Your vote has been accepted!',
        hash: voteTx.hash,
    }
}

async function checkState(governer, proposalId) {
    console.log('Check state...')
    let proposalState = voteStatus(await governer.state(proposalId))
    console.log(`Propose status: ${proposalState}`)

    if (proposalState === 'Pending') {
        // await moveBlocks(1)
        await new Promise(r => setTimeout(r, 2000))
        proposalState = voteStatus(await governer.state(proposalId))
    }

    if (proposalState === 'Succeeded') {
        return {
            status: 'success',
            title: 'Success',
            message: 'You have already voted!',
        }
    }

    if (proposalState !== 'Active') {
        return {
            status: 'error',
            title: 'Error',
            message: `Propose status: ${proposalState}`,
        }
    }
    // console.log('Check state done!')
    return true
}
