export default [
    {
        path: 'register',
        name: 'auth-register',
        component: () => import('@/views/finbridge/auth/Register.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: 'login',
        name: 'auth-login',
        component: () => import('@/views/finbridge/auth/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: 'forgot-password',
        name: 'auth-forgot-password',
        component: () => import('@/views/finbridge/auth/ForgotPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: 'reset-password',
        name: 'auth-reset-password',
        component: () => import('@/views/finbridge/auth/ResetPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
    },
]
