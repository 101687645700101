import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueMeta from 'vue-meta'
import VCalendar from 'v-calendar'
// import * as Sentry from "@sentry/vue"
// import { BrowserTracing } from "@sentry/tracing"
import MarqueeText from 'vue-marquee-text-component'
import PortalVue from 'portal-vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import i18n from '@/libs/i18n'
import VueCarousel from 'vue-carousel'
import VueScrollTo from 'vue-scrollto'
import router from './router'
import store from './store'
import App from './App.vue'
// import VueIpfs from './libs/vue-ipfs'
// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueMeta)
Vue.use(VCalendar, {
    componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
})
Vue.use(PortalVue)
Vue.component('MarqueeText', MarqueeText)
Vue.use(VueReCaptcha, {
    siteKey: process.env.RECAPCHA_SITE_KEY,
    loaderOptions: {
        autoHideBadge: true,
    },
})
Vue.use(VueCarousel)
Vue.use(VueScrollTo)
// Vue.use(VueIpfs)
// if (process.env.NODE_ENV === 'production') {
//     Sentry.init({
//         Vue,
//         environment: process.env.NODE_ENV,
//         release: `${process.env.NODE_ENV}`,
//         dsn: process.env.SENTRY_DSN,
//         integrations: [
//             new BrowserTracing({
//                 tracingOrigins: [process.env.SENTRY_HOST, /^\//],
//             }),
//         ],
//         tracesSampleRate: 1,
//         tracingOptions: {
//             trackComponents: true,
//         },
//         // Vue specific
//         attachProps: true,
//         attachStacktrace: true,
//     })
// }

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

i18n.locale = 'en'

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
