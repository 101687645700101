import { governanceToken, mainGoverner } from '@/libs/blockchain/config'
import { voteStatus } from './functions'

export default async function addDebenture(proposalId, borrower, lenders, amounts) {
    console.log('Add debenture...')

    const proposalState = voteStatus(await mainGoverner.state(proposalId))
    console.log(`Propose status: ${proposalState}`)

    if (proposalState !== 'Succeeded' && proposalState !== 'Queued') {
        return {
            status: 'error',
            title: 'Execute error',
            message: `Propose status: ${proposalState}`,
        }
    }
    const addDebentureTx = governanceToken.addDebenture(proposalId, borrower, lenders, amounts).catch(e => {
        console.log(e.body ? JSON.parse(e.body).error.message : e)
        return false
    })
    if (!addDebentureTx) {
        return {
            status: 'error',
            title: 'Execute error',
            message: '',
        }
    }

    return {
        status: 'success',
        hash: addDebentureTx.hash,
    }
}
