import {
    ethers, mainGoverner, governanceToken, GovernorContract,
} from '@/libs/blockchain/config'
import { voteStatus } from './functions'

export default async function queue(request) {
    console.log('Queue...')

    const proposalState = voteStatus(await mainGoverner.state(request.proposalId))
    console.log(`Propose status: ${proposalState}`)

    if (proposalState !== 'Succeeded' && proposalState !== 'Queued') {
        if (proposalState === 'Canceled' || proposalState === 'Defeated') {
            const voters = await mainGoverner.getVoters(request.id)
            const freezeCancellationTx = await governanceToken.freezeCancellation(voters[0].voter, voters[0].amount)
        }
        return {
            status: 'error',
            title: 'Queue error',
            message: `Propose status: ${proposalState}`,
        }
    }

    const encodedFunctionCall = governanceToken.interface.encodeFunctionData('sendLoans', [request.to, GovernorContract.address, request.id])
    const descriptionHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(request.desc))
    // could also use ethers.utils.id(description)

    if (proposalState === 'Succeeded') {
        console.log("Queueing...") // После запуска mainGoverner.queue предложение оказывается в обработке
        console.log(request.desc)
        const queueTx = await mainGoverner
            .queue(
                [governanceToken.address],
                [0],
                [encodedFunctionCall],
                descriptionHash,
            )
            .catch(e => {
                /**
                 * Ошибка может быть из-за неверно переданных параметров id и описания. Из них считается
                 * proposalId и береться его статус
                 *
                 * */
                console.log(e.body ? JSON.parse(e.body).error.message : e)
                return false
            })
        if (!queueTx) {
            return {
                status: 'error',
                title: 'Queue error',
                message: '',
            }
        }
        await queueTx.wait(1)
        console.log("Queueing...")

        console.log("Queued")
        return {
            status: 'success',
            title: 'Success',
            message: 'Queued!',
            hash: queueTx.hash,
        }
    }
    console.log(`Not correct proposalState: ${proposalState}`)
}
