// DAO
import useSession from '@/auth/session/useSession'
import vote from './dao/vote'
import queue from './dao/queue'
import execute from './dao/execute'
import propose from './dao/propose'
import status from './dao/status'
import state from './dao/state'
import delegate from './dao/delegate'
import buyTokens from './dao/buyTokens'
import sellTokens from './dao/sellTokens'
import addDebenture from './dao/addDebenture'
// ERC20
import send from './erc20/send'
import createWallet from './erc20/createWallet'

export default class Blockchain {
    static async login(succeessHandler, errorHandler) {
        return await Blockchain.getWallet().then(data => {
            useSession.login(useSession.sessionConfig.ethEndpoint, {
                signature: data[0],
                provider: "metamask",
            })
                .then(async response => await succeessHandler(response))
                .catch(async error => await errorHandler(error))
        }).catch(error => console.log(error))
    }

    static async register(succeessHandler, errorHandler) {
        return await Blockchain.getWallet().then(data => {
            useSession.register(useSession.sessionConfig.ethEndpoint, {
                signature: data[0],
                provider: "metamask",
            })
                .then(async response => await succeessHandler(response))
                .catch(async error => await errorHandler(error))
        }).catch(error => errorHandler(error))
    }

    static async connect(succeessHandler, errorHandler) {
        return await Blockchain.getWallet().then(data => {
            useSession.login('/settings/auth-methods', {
                signature: data[0],
                provider: "metamask",
            })
                .then(async response => await succeessHandler(response))
                .catch(async error => await errorHandler(error))
        }).catch(error => console.log(error))
    }

    // System
    static async getWallet() {
        return await window.ethereum.request({ method: 'eth_requestAccounts' })
    }

    // DAO
    static async propose(id, jsonDescription, to) {
        return propose(id, jsonDescription, to)
    }

    // DAO
    static async vote(request) {
        return await vote(request.votingAddress, request.proposalId, request.sum, request.id, request.percent)
    }

    // DAO
    static async queue(request) {
        return await queue(request)
    }

    // DAO
    static async execute(request) {
        return await execute(request)
    }

    // DAO Features
    static async state(proposalId, modelId) {
        return await state(proposalId, modelId)
    }

    // DAO Features
    static async status(request) {
        return await status(request)
    }

    // ERC20
    static async send(request) {
        return await send(request)
    }

    // ERC20
    static async createWallet() {
        return await createWallet()
    }

    static async delegate() {
        return await delegate()
    }

    static async buyTokens(amountUSDT, amountYT) {
        return await buyTokens(amountUSDT, amountYT)
    }

    static async sellTokens(amountUSDT, amountYT) {
        return await sellTokens(amountUSDT, amountYT)
    }

    static async addDebenture(proposalId, borrower, lenders, mounts) {
        return await addDebenture(proposalId, borrower, lenders, mounts)
    }
}
