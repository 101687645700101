// *===============================================---*
// *--------- Kyc-request -------------------------------------------*
// *===============================================---*
export default [
    {
        path: 'admin/kyc-request',
        name: 'kyc-request',
        component: () => import('@/views/finbridge/kyc-request/kyc-request-list/KycRequestList.vue'),
        meta: {
            sitemap: {
                ignoreRoute: true,
            },
        },
    },
    {
        path: 'admin/kyc-request/:id',
        name: 'kyc-request-view',
        component: () => import('@/views/finbridge/kyc-request/KycRequestView.vue'),
        meta: {
            sitemap: {
                ignoreRoute: true,
            },
        },
    },
]
