// *===============================================---*
// *--------- USER ---- ---------------------------------------*
// *===============================================---*
export default process.env.NODE_ENV === 'production' ? [] : [
    {
        path: 'users/list',
        name: 'users-list',
        component: () => import('@/views/apps/user/users-list/UsersList.vue'),
        meta: {
            resource: 'UsersList',
            action: 'read',
        },
    },
    {
        path: 'users/view/:id',
        name: 'users-view',
        component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    },
    {
        path: 'users/edit/:id',
        name: 'users-edit',
        component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
    },
]
