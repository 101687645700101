export const ethers = require('ethers')

export const provider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum) : null
console.log('Provider: ' + (window.ethereum ? 'window.ethereum' : null))

export const signer = provider?.getSigner()

export const GovernorContract = require('../../dao/GovernorContract.json')

export const GovernanceToken = require('../../dao/GovernanceToken.json')

export const mainGoverner = new ethers.Contract(GovernorContract.address, GovernorContract.abi, signer)

export const governanceToken = new ethers.Contract(GovernanceToken.address, GovernanceToken.abi, signer)

export const nftContract = require('../../dao/YOUR_TOKEN.json')

export const NftContract = new ethers.Contract(nftContract.address, nftContract.abi, signer)

const Exchange = require('../../dao/Exchange.json')

export const exchange = new ethers.Contract(Exchange.address, Exchange.abi, signer)

const USDT = require('../../dao/USDT.json')

export const usdt = new ethers.Contract(USDT.address, USDT.abi, signer)
