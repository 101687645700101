import { provider } from '@/libs/blockchain/config'

export function voteStatus(id) {
    switch (id) {
    case 0:
        return "Pending" // В ожидании
    case 1:
        return "Active" // Голосование активно
    case 2:
        return "Canceled" // Отменено
    case 3:
        return "Defeated" // Побежденный
    case 4:
        return "Succeeded" // Пользователь проголосовал
    case 5:
        return "Queued" // В очереди на принятие финального решения
    case 6:
        return "Expired" // Истек
    case 7:
        return "Executed" // Выполнен
    default:
        return 'Canceled'
    }
}

export async function moveBlocks(amount) {
    console.log("Moving blocks...")
    for (let index = 0; index < amount; index++) {
        await provider.send(
            "evm_mine",
            [],
        )
    }
    console.log(`Moved ${amount} blocks`)
}

export async function moveTime() {
    console.log("Moving blocks...")
    await provider.send("evm_increaseTime", [120]) // 2 min

    console.log(`Moved forward in time 120 seconds`)
}
