// *===============================================---*
// *--------- marketplace ---- ---------------------------------------*
// *===============================================---*

export default [
    {
        path: 'marketplace',
        name: 'marketplace',
        component: () => import('@/views/finbridge/marketplace/marketplace-list/MarketplaceList.vue'),
    },
    {
        path: 'marketplace/create',
        name: 'marketplace-create',
        component: () => import('@/views/finbridge/marketplace/marketplace-create/MarketplaceCreate.vue'),
    },
    {
        path: 'marketplace/:id',
        name: 'marketplace-view',
        component: () => import('@/views/finbridge/marketplace/marketplace-view/MarketplaceView.vue'),
        meta: {
            sitemap: {
                ignoreRoute: true,
            },
        },
    },
]
