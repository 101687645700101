import {
    mainGoverner,
} from '@/libs/blockchain/config'
// import {
//     ethers, provider, GovernorContract,
// } from '@/libs/blockchain/config'
import { voteStatus } from './functions'

export default async function status(proposalId) {
    // const accounts = await provider.listAccounts()
    // let hasVoted = 0
    // let total = 0

    // for (const votingAddress of accounts) {
    // // Работаем с контрактом и данным propose от votingAddress
    //     const voting = await provider.getSigner(votingAddress)
    //     const governer = new ethers.Contract(GovernorContract.address, GovernorContract.abi, voting)

    //     const addressHasVoted = await governer.hasVoted(proposalId, votingAddress)
    //     total += 1
    //     if (addressHasVoted) {
    //         hasVoted += 1
    //     }
    // }
    const proposalState = voteStatus(await mainGoverner.state(proposalId))
    console.log(`Propose status: ${proposalState}`)

    const proposalSnapShot = await mainGoverner.proposalSnapshot(proposalId)
    const proposalDeadline = await mainGoverner.proposalDeadline(proposalId)

    // Порядковый номер блока в котором записали Propose
    console.log(`Current Proposal Snapshot: ${proposalSnapShot}`) // 41
    // Номер блока, по которому истекает срок голосования по предложению
    console.log(`Current Proposal Deadline: ${proposalDeadline}`) // 47

    const quorum = await mainGoverner
        .quorum(proposalSnapShot - 1)
        .catch(() => {
            console.log('Quorum info: error')
            return false
        })

    if (quorum) {
        console.log(`Quorum: ${quorum / 10000000000000000000000}%`)
    // Quorum: 50000000000000000000000 = 5%
    }

    // console.log({
    //     total, // on dev = 20
    //     hasVoted,
    // })
    // return {
    //     total, // on dev = 20
    //     hasVoted,
    // }
}
