import { signer, ethers } from '@/libs/blockchain/config'

export default async function delegate() {
    const signerAddress = await signer.getAddress()

    const GovernanceToken = require('../../../dao/GovernanceToken.json')
    const governanceToken = new ethers.Contract(GovernanceToken.address, GovernanceToken.abi, signer)
    const signerAddressBalance = await governanceToken.balanceOf(signerAddress)

    if (signerAddressBalance > 0) {
        const balance = await governanceToken.balanceOf(signerAddress)
        console.log(`${balance} : ${signerAddress}`)
        await governanceToken.delegate(signerAddress)
        const checkPoint = await governanceToken.numCheckpoints(signerAddress)
        console.log(checkPoint)
    }
}
