export default [
    {
        path: 'error-404',
        name: 'error-404',
        component: () => import('@/views/finbridge/error/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
            sitemap: {
                ignoreRoute: true,
            },
        },
    },
]
