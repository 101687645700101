import { mainGoverner } from '@/libs/blockchain/config'
import Propose from '@/api/propose'
import { voteStatus } from './functions'

export default async function state(proposalId) {
    console.log(`Try blockchain with proposalId: ${proposalId}`)

    const currentState = await mainGoverner
        .state(proposalId)
        .catch(e => {
            console.log(e.body ? JSON.parse(e.body).error.message : e)
            return false
        })

    return voteStatus(currentState)
}
