import axiosIns from '@/libs/axios'

export default class ProposeAPI {
    static async index(request) {
        return (await axiosIns.get('/propose', { params: request })).data
    }

    static async store(request) {
        return (await axiosIns.post('/propose', request)).data
    }

    static async show(request) {
        return (await axiosIns.get(`/propose/${request.id}`)).data
    }

    static async update(data) {
        return (await axiosIns.post(`/propose/${data.id}`, data)).data
    }
}
