import { ethers, GovernorContract } from '@/libs/blockchain/config'

// eslint-disable-next-line no-unused-vars
export default async function sendTransaction(request) {
    if (request.signer === undefined) {
        throw new Error('signer not found')
    }
    if (request.toAddress === undefined) {
        throw new Error('toAddress not found')
    }
    if (request.sendTokenAmount === undefined) {
        throw new Error('sendTokenAmount not found')
    }
    // const sendTokenAmount = "0.005"

    if (request.isERC20) {
        const contract = new ethers.Contract(
            GovernorContract.address,
            GovernorContract.abi,
            request.signer,
        )
        const contractSigner = contract.connect(request.signer)

        // How many tokens?
        const numberOfTokens = ethers.utils.parseUnits(request.sendTokenAmount, 18)
        console.log(`numberOfTokens: ${numberOfTokens}`)

        // Send tokens
        contractSigner.transfer(request.toAddress, numberOfTokens).then(transferResult => {
            console.dir(transferResult)
            console.log("sent token")
        })
    } else {
        const tx = {
            to: request.toAddress,
            value: ethers.utils.parseEther(request.sendTokenAmount),
        }

        await request.signer.sendTransaction(tx).then(transaction => {
            console.dir(transaction)
            console.log('Send success!')
        })
    }
}
