import { mainGoverner, governanceToken, GovernorContract } from '@/libs/blockchain/config'
import {
    voteStatus,
} from './functions'

export default async function propose(id, jsonDescription, to) {
    console.log('---------------------Start------------------------------')

    const encodedFunctionCall = governanceToken.interface.encodeFunctionData('sendLoans', [to, GovernorContract.address, id])

    console.log(`Proposing ${governanceToken.address} with project id: ${id}`)
    console.log(`Proposal Description:  ${jsonDescription}`)
    //
    const proposeTx = await mainGoverner
        .propose(
            [governanceToken.address],
            [0],
            [encodedFunctionCall],
            jsonDescription,
        )
        .catch(e => {
            console.log(e)
            return {
                status: 'failed',
                message: e.body ? JSON.parse(e.body).error.message : console.log(e),
            }
        })

    if (proposeTx.status === 'failed') { return proposeTx }

    if (!proposeTx) { return false }

    const proposeReceipt = await proposeTx.wait(1)

    const { proposalId } = proposeReceipt.events[0].args
    console.log(`Proposed with proposal ID:  ${proposalId}`)

    const proposalState = voteStatus(await mainGoverner.state(proposalId))
    console.log(`Status: ${proposalState}`)

    return {
        status: 'success',
        hash: proposeTx.hash,
        proposal_id: proposalId,
    }
}
