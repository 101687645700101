// Invoice

export default [
    {
        path: 'invest/:id/invoice/create',
        name: 'app-invoice-create',
        component: () => import('@/views/finbridge/invoice/invoice-create/InvoiceCreate.vue'),
        meta: {
            sitemap: {
                ignoreRoute: true,
            },
        },
    },
]
