import {
    ethers, mainGoverner, governanceToken, GovernorContract,
} from '@/libs/blockchain/config'
import { voteStatus } from './functions'

export default async function execute(request) {
    console.log('Execute...')

    const proposalState = voteStatus(await mainGoverner.state(request.proposalId))
    console.log(`Propose status: ${proposalState}`)

    if (proposalState !== 'Succeeded' && proposalState !== 'Queued') {
        return {
            status: 'error',
            title: 'Execute error',
            message: `Propose status: ${proposalState}`,
        }
    }

    const encodedFunctionCall = governanceToken.interface.encodeFunctionData('sendLoans', [request.to, GovernorContract.address, request.id])
    const descriptionHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(request.desc))
    // could also use ethers.utils.id(description)
    console.log("Executing...")
    // this will fail on a testnet because you need to wait for the MIN_DELAY!
    const executeTx = await mainGoverner.execute(
        [governanceToken.address],
        [0],
        [encodedFunctionCall],
        descriptionHash,
    )
        .catch(e => {
            console.log(e.body ? JSON.parse(e.body).error.message : e)
            return false
        })
    if (!executeTx) {
        return {
            status: 'error',
            title: 'Execute error',
            message: '',
        }
    }
    await executeTx.wait(1)

    console.log(`-----------------------------------------\n\n\n`)
    return {
        status: 'success',
        hash: executeTx.hash, // executeTx.hash,
    }
}
