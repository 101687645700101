import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import {
    getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider,
} from 'firebase/auth'
import User from '@/api/user'
import SettingsAPI from '@/api/settings'
import useSession from '@/auth/session/useSession'

const user = User.cache()

const firebaseConfig = {
    apiKey: "AIzaSyD_aXcY9nWKa-t4Um4PTCsshUAHN27Bh6A",
    authDomain: "finbridge-blockchain.firebaseapp.com",
    projectId: "finbridge-blockchain",
    storageBucket: "finbridge-blockchain.appspot.com",
    messagingSenderId: "517781726959",
    appId: "1:517781726959:web:1e1f6d8aad84cf60375d1a",
    measurementId: "G-WQ2FZZSENQ",
}

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)
const auth = getAuth(app)

export default class Firebase {
    // static async google(succeessHandler, errorHandler) {
    //     const provider = new GoogleAuthProvider()
    //     provider.setCustomParameters({
    //         prompt: "select_account",
    //     })

    //     return await signInWithPopup(auth, provider)
    //         .then(async result => {
    //             const now = Math.round(+new Date() / 1000)
    //             const userData = result.user
    //             const createdAt = userData.auth.currentUser.metadata.createdAt / 1000
    //             const token = userData.uid
    //             if (now - createdAt > 120 || user) {
    //                 await useSession.login(useSession.sessionConfig.firebaseEndpoint, {
    //                     signature: token,
    //                     provider: "google",
    //                 })
    //                     .then(async res => {
    //                         console.log(res)
    //                         if (succeessHandler) {
    //                             return await succeessHandler(res)
    //                         } return res
    //                     })
    //                     .catch(async error => {
    //                         console.log(error)
    //                         if (errorHandler) {
    //                             return await errorHandler(error)
    //                         }
    //                         return error
    //                     })
    //                 return true
    //             }
    //             return await useSession.register(useSession.sessionConfig.firebaseEndpoint, { signature: token, provider: "google" })
    //                 .then(async response => {
    //                     await succeessHandler(response)
    //                     console.log(response)
    //                     // if (response.data.general.email === '') {
    //                     //     Firebase.updateUserInfo(userData.email, userData.displayName, userData.phoneNumber, userData.photoURL)
    //                     // }
    //                 })
    //                 .catch(async error => {
    //                     await errorHandler(error)
    //                 })
    //         })
    //         .catch(error => console.log(error))
    // }

    static async apple(succeessHandler, errorHandler) {
        const provider = new OAuthProvider('apple.com')
        signInWithPopup(auth, provider)
            .then(result => {
                // The signed-in user info.
                const user = result.user
                // Apple credential
                const credential = OAuthProvider.credentialFromResult(result)
                const accessToken = credential.accessToken
                const idToken = credential.idToken
            })
            .catch(error => console.log(error))
    }

    static async requestPermission() {
        if (user && user.notification.push) {
            await Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    getToken(messaging, {
                        vapidKey: process.env.FIREBASE_VAPID_KEY,
                    }).then(currentToken => {
                        if (currentToken) {
                            if (!localStorage.getItem('notifications')) { localStorage.setItem('notifications', JSON.stringify([])) }
                            console.log(currentToken)
                        } else {
                            console.log('Can not get token')
                        }
                    })
                } else {
                    console.log('Do not have permission!')
                }
            })
        }
    }

    static async updateUserInfo(email, displayName, phoneNumber, photoURL) {
        const formData = new FormData()

        formData.set('email', email)
        formData.set('name', displayName)
        formData.set('phone', phoneNumber)
        formData.set('avatar_url', photoURL)

        await SettingsAPI.general(formData).then().catch(error => {
            console.log(error)
        })
    }
}

const notificationChannel = new BroadcastChannel('channel4')

notificationChannel.onmessage = event => {
    if (user.notification.push) {
        const notifications = JSON.parse(localStorage.getItem('notifications'))
        notifications.push(event.data[0].notification)
        localStorage.setItem('notifications', JSON.stringify(notifications))
    }
}

onMessage(messaging, payload => {
    const notificationTitle = payload.data.title
    const notificationOptions = {
        body: payload.data.body,
        icon: payload.data.icon,
    }
    if (user.notification.push) {
        const notification = new Notification(notificationTitle, notificationOptions)
    }

    console.log('fcm on focus')
})
