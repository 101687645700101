// Invoice
export default process.env.NODE_ENV === 'production' ? [] : [
    {
        path: 'apps/product/invoice/list',
        name: 'apps-product-invoice-list',
        component: () => import('@/views/apps/product-invoice/product-invoice-list/ProductInvoiceList.vue'),
    },
    {
        path: 'apps/product/invoice/preview/:id',
        name: 'apps-product-invoice-preview',
        component: () => import('@/views/apps/product-invoice/product-invoice-preview/ProductInvoicePreview.vue'),
    },
    {
        path: 'apps/product/invoice/add/',
        name: 'apps-product-invoice-add',
        component: () => import('@/views/apps/product-invoice/product-invoice-add/ProductInvoiceAdd.vue'),
    },
    {
        path: 'apps/product/invoice/edit/:id',
        name: 'apps-product-invoice-edit',
        component: () => import('@/views/apps/product-invoice/product-invoice-edit/ProductInvoiceEdit.vue'),
    },
]
