import axiosIns from '@/libs/axios'

export default class User {
    static cache() {
        return JSON.parse(localStorage.getItem('userData'))
    }

    static async show() {
        return (await axiosIns.get(`/user/show`)).data
    }

    static getEthWallet() {
        const user = JSON.parse(localStorage.getItem('userData'))
        if (user.wallets.length > 0) {
            return user.wallets.filter(wallet => wallet.currency === 'ETH')[0].address
        }
        return ''
    }

    static cacheUser(user) {
        localStorage.setItem('userData', JSON.stringify(User.get(user)))
    }

    static get(user) {
        const {
            general, kyc, notification, wallets, ability, extras, auth_methods,
        } = user.data
        return {
            id: general.id,
            avatar: general.avatar ?? '',
            name: general.name ?? '',
            username: general.username ?? '',
            email: general.email,
            role: general.role,
            status: kyc.status,
            authMethods: auth_methods,

            general,
            kyc,
            notification,
            wallets,

            ability,
            extras,
        }
    }

    static async checkVerify(code) {
        return (await axiosIns.get(`/user/email-check-verify?verify_code=${code}`)).data
    }

    static async emailVerify() {
        return (await axiosIns.get('/user/email-verify')).data
    }
}
