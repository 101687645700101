// *===============================================---*
// *--------- Debug -------------------------------------------*
// *===============================================---*

export default process.env.NODE_ENV === 'production' ? [
    {
        path: 'admin/propose',
        name: 'admin-propose',
        component: () => import('@/views/finbridge/marketplace/marketplace-list/marketplace-admin/MarketplaceAdminList.vue'),
        meta: {
            sitemap: {
                ignoreRoute: true,
            },
        },
    },
] : [
    {
        path: 'admin/debug',
        name: 'admin-debug',
        component: () => import('@/views/finbridge/admin/Admin.vue'),
        meta: {
            sitemap: {
                ignoreRoute: true,
            },
        },
    },
    {
        path: 'admin/propose',
        name: 'admin-propose',
        component: () => import('@/views/finbridge/marketplace/marketplace-list/marketplace-admin/MarketplaceAdminList.vue'),
        meta: {
            sitemap: {
                ignoreRoute: true,
            },
        },
    },
]
