export default process.env.NODE_ENV === 'production' ? [] : [{
    path: 'pages/blog/list',
    name: 'pages-blog-list',
    component: () => import('@/views/apps/blog/BlogList.vue'),
    meta: {
        pageTitle: 'Blog List',
        breadcrumb: [
            {
                text: 'Pages',
            },
            {
                text: 'Blog',
            },
            {
                text: 'List',
                active: true,
            },
        ],
    },
},
{
    path: 'pages/blog/:id',
    name: 'pages-blog-detail',
    component: () => import('@/views/apps/blog/BlogDetail.vue'),
    meta: {
        pageTitle: 'Blog Detail',
        breadcrumb: [
            {
                text: 'Pages',
            },
            {
                text: 'Blog',
            },
            {
                text: 'Detail',
                active: true,
            },
        ],
    },
},
{
    path: 'pages/blog/edit/:id',
    name: 'pages-blog-edit',
    component: () => import('@/views/apps/blog/BlogEdit.vue'),
    meta: {
        pageTitle: 'Blog Edit',
        breadcrumb: [
            {
                text: 'Pages',
            },
            {
                text: 'Blog',
            },
            {
                text: 'Edit',
                active: true,
            },
        ],
    },
}]
