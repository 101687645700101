export default [
    {
        path: 'learn',
        name: 'learn-center',
        component: () => import('@/views/finbridge/learn-center/LearnCenter.vue'),
        meta: {
            pageTitle: 'Learn center',
        },
    },
]
