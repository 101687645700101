// *===============================================---*
// *--------- CHAT  ---------------------------------------*
// *===============================================---*
export default process.env.NODE_ENV === 'production' ? [] : [
    {
        path: 'apps/chat',
        name: 'apps-chat',
        component: () => import('@/views/apps/chat/Chat.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'chat-application',
        },
    },
]
