import { ethers } from '@/libs/blockchain/config'

export default async function createWallet() {
    const newWallet = ethers.Wallet.createRandom()
    // Сохранить newWallet.privateKey в бд
    return {
        status: 200,
        wallet: {
            address: newWallet.address, // Авторизация по приватному ключу
            privateKey: newWallet.privateKey,
        },
    }
}
