// *===============================================---*
// *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
// *===============================================---*
export default process.env.NODE_ENV === 'production' ? [] : [
    {
        path: 'apps/email',
        name: 'apps-email',
        component: () => import('@/views/apps/email/Email.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
        },
    },
    {
        path: 'apps/email/:folder',
        name: 'apps-email-folder',
        component: () => import('@/views/apps/email/Email.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            navActiveLink: 'apps-email',
        },
        beforeEnter(to, _, next) {
            if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
            else next({ name: 'error-404' })
        },
    },
    {
        path: 'apps/email/label/:label',
        name: 'apps-email-label',
        component: () => import('@/views/apps/email/Email.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            navActiveLink: 'apps-email',
        },
        beforeEnter(to, _, next) {
            if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
            else next({ name: 'error-404' })
        },
    },
]
