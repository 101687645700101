import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
    faqData: {
    // payment
        payment: {
            icon: 'CreditCardIcon',
            title: 'Wallet',
            subtitle: 'This section describes basic wallet operations',
            qandA: [
                {
                    question: 'Creating a wallet',
                    ans: 'When you create an account, a wallet is automatically created for you in the BEP20 network (supports ERC20).',
                },
                {
                    question: 'Wallet replenishment',
                    ans: 'We only use the BEP20 network for deposits, as this network has a low service fee for transactions',
                },
                {
                    question: 'Withdrawals',
                    ans: 'You can withdraw funds at any time. The service fee is required to pay for gas in the network and does not exceed 0.5 USDT. The withdrawal will be made through the BEP20 network.',
                },
                {
                    question: 'How to verify a transaction?',
                    ans: 'You can check the transaction against the information in your wallet or through the network explorer: ',
                    link: process.env.NODE_SEARCH_URL,
                },
            ],
        },
        // delivery
        delivery: {
            icon: 'DownloadIcon',
            title: 'Getting a loan',
            qandA: [
                {
                    question: 'What data is needed to complete an application?',
                    ans: `Information about the company: name, abbreviated name, TIN, OKVED, profit for the month, commodity balance, information about activities in free form. Contact details: website, address, phone, mail. Investment data: purpose of attraction, conditions, amount, expiration date of return, investment presentation.`,
                },
                {
                    question: 'Is there a service fee?',
                    ans: 'The service fee is only for paying the commission for publishing data in the blockchain network.',
                },
                {
                    question: 'Under what conditions can I get a loan?',
                    ans: 'You yourself form the conditions in the application. Next, we will contact you to confirm the terms. It also affects your credit history and the credit rating of the company.',
                },
                {
                    question: 'How are the conditions negotiated and the companies verified?',
                    ans: 'Our specialists will study your company according to the criteria approved by the platform. Agree on the terms of the loan at an online meeting, request the necessary documents and assign a credit rating.',
                },
                {
                    question: 'How is loan data published on the blockchain?',
                    ans: `The platform publishes loan data in dao format and acts as the sole holder of voting tokens. The company's loan data is now in the public blockchain network and cannot be changed.`,
                },
                {
                    question: 'How is the fundraising going?',
                    ans: 'After the publication of the investment proposal, fundraising will begin. After the deadline and in case of successful collection of the entire amount, it will be transferred to your wallet. You can withdraw funds at any time.',
                },
                {
                    question: 'What happens in case of delay?',
                    ans: `Your application goes into manual processing mode by the security department. In case of delay, fines in the amount of 1% of the loan per day of delay are charged. If the loan is repaid, the company's investors receive penalty interest. If it is impossible to obtain a loan, we will request payment through the transaction security specified in the agreement.`,
                },
            ],
        },
        myOrders: {
            icon: 'UploadIcon',
            title: 'Loan repayment',
            qandA: [
                {
                    question: 'How is the loan repaid?',
                    ans: 'В день погашения части займа необходимо иметь на балансе нужно количество средств. Погашение просиходит каждый день.',
                },
                {
                    question: 'What happens in case of delay?',
                    ans: 'Ваша заявка переходит в режим ручной обработки отделом безопастности.'
                        + ' В случае просрочки начисляются штрафы в размере 1% от займа за день просрочки. В случае погашения займа штрафные проценты получают инвесторы компании.'
                        + ' В случае невозможности получения займа мы запросим выплату через указанное в договоре обеспечение сделки.',
                },
            ],
        },
        // cancellation and return
        cancellationReturn: {
            icon: 'TrendingUpIcon',
            title: 'Company rating',
            subtitle: 'What does a company rating mean for a loan?',
            qandA: [
                {
                    question: 'А+',
                    ans: 'The most reliable borrowers whose business is stable and whose cash flow is well predictable. As a rule, these are companies that previously took loans on Lemon.online and successfully repaid them without serious violations.":"Самые надежные заемщики, бизнес которых стабилен, а денежный поток хорошо предсказуем. Как правило, это компании, которые ранее брали займы на Lemon.online и успешно погашали их без серьезных нарушений.',
                },
                {
                    question: 'A',
                    ans: 'Reliable borrowers whose business is stable and whose cash flow is predictable.',
                },
                {
                    question: 'A-',
                    ans: 'Borrowers whose business is stable, cash flow is predictable, but there may be periods of instability in revenue or other negative factors.',
                },
                {
                    question: 'B+',
                    ans: 'Borrowers who, as a rule, have a relatively young business (up to 3 years) and small average turnover (up to 500 thousand rubles per month). The cash flow of these borrowers is predictable, but there may be periods of instability in the flow of revenue or other negative factors.',
                },
                {
                    question: 'B',
                    ans: 'Borrowers who, as a rule, have a relatively young business (up to 3 years) and small average turnovers (up to 500 thousand rubles per month), which allowed delays of the category up to 30 days on loans over the past 5 years. The cash flow of these borrowers is predictable, but there may be periods of instability in the flow of revenue or other negative factors.',
                },
                {
                    question: 'B-',
                    ans: 'Borrowers who, as a rule, have a relatively young business (up to 3 years) and small average turnovers (up to 300 thousand rubles per month), which allowed delays of the category up to 30 days on loans over the past 5 years. The cash flow of these borrowers is predictable, but there may be periods of instability in the flow of revenue or other negative factors.',
                },
            ],
        },
        // product and services
        productServices: {
            icon: 'CpuIcon',
            title: 'Service fee',
            subtitle: 'In what cases, under what conditions and for what does the company charge a service fee?',
            qandA: [
                {
                    question: 'Loan service',
                    ans: 'The platform charges a commission of 1% of the investment.',
                },
            ],
        },

        goerliTransactions: {
            icon: 'SearchIcon',
            title: 'Check transaction',
            qandA: [
                {
                    question: 'How do I read transaction details?',
                    ans: 'To check the details of your transactions, add the transaction hash into the search tab: ',
                    link: process.env.NODE_SEARCH_URL,
                },
                {
                    question: 'What is transaction hash and where I can find it?',
                    ans: 'Before created, a transaction receives an alphanumeric identifier, hash, or code with “0x.” You can find all transaction hashes on proposal detail page, in «сompany details» section.',
                },
                {
                    question: 'How check info about voting result?',
                    ans: 'If the propose status is succeeded and company details section includes executed hesh, the vote was successful! You can verify this by copying the executed hash and checking the status of the transaction on: ',
                    link: process.env.NODE_SEARCH_URL,
                },
            ],

        },

        // documentation
        documentation: {
            icon: 'MoreHorizontalIcon',
            title: 'Прочее',
            qandA: [
                {
                    question: 'Life cycle of DAO.',
                    ans: 'Diagram:',
                    img: "https://miro.medium.com/max/1400/1*gpO98UpViry8_ZG43mjyWA.jpeg",
                },
            ],
        },
    },
}

mock.onGet('/faq/data').reply(config => {
    const { q = '' } = config.params
    const queryLowered = q.toLowerCase()

    const filteredData = {}

    Object.entries(data.faqData).forEach(entry => {
        const [categoryName, categoryObj] = entry
        // eslint-disable-next-line arrow-body-style
        const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
            return qAndAObj.question.toLowerCase().includes(queryLowered)
        })
        if (filteredQAndAOfCategory.length) {
            filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
        }
    })

    return [200, filteredData]
})
