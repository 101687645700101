export default [
    {
        path: '/',
        name: 'landing',
        component: () => import('@/views/finbridge/landing/Landing.vue'),
        meta: {
            pageTitle: 'Landing',
        },
    },
    {
        path: 'about',
        name: 'about-us',
        component: () => import('@/views/finbridge/landing/LandingAbout.vue'),
        meta: {
            pageTitle: 'About us',
            breadcrumb: [
                {
                    text: 'Main',
                },
                {
                    text: 'About us',
                    active: true,
                },
            ],
        },
    },
    {
        path: 'contacts',
        name: 'contacts',
        component: () => import('@/views/finbridge/landing/LandingContacts.vue'),
        meta: {
            pageTitle: 'Contacts',
            breadcrumb: [
                {
                    text: 'Main',
                },
                {
                    text: 'Contacts',
                    active: true,
                },
            ],
        },
    },
]
