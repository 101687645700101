export default [
    {
        path: 'settings/:tab(kyc|wallets|change_password|notifications|two_fa|block)?',
        name: 'setting',
        component: () => import('@/views/finbridge/settings/AccountSettings.vue'),
        meta: {
            pageTitle: 'Account Settings',
            breadcrumb: [
                {
                    text: 'Pages',
                },
                {
                    text: 'Account Settings',
                    active: true,
                },
            ],

            sitemap: {
                slugs: [
                    {
                        tab: 'change_password',
                    },
                    {
                        tab: 'notifications',
                    },
                    {
                        tab: 'two_fa',
                    },
                    {
                        tab: 'block',
                    },
                ],
            },

        },
    },
]
