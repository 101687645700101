import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import i18n from "@/libs/i18n"
import apps from './apps/index'
import chartsMaps from './charts-maps/charts-maps'
import dashboard from './dashboards/dashboard'
import finbridge from './finbridge/index'
import formsTable from './forms-tables/forms-tables'
import pages from './pages/pages'
import uiElements from './ui-elements/index'

const routes = [
    {
        path: "/",
        redirect: i18n.locale,
    },
    process.env.NODE_ENV !== 'production'
        ? {
            path: "/:locale(ru|en)",
            redirect: i18n.locale,
            component: () => import('./Root.vue'),
            children: [

                ...apps,
                ...dashboard,
                ...pages,
                ...chartsMaps,
                ...formsTable,
                ...uiElements,
                ...finbridge,
            ],
        }
        : {
            path: "/:locale(ru|en)",
            redirect: i18n.locale,
            component: () => import('./Root.vue'),
            children: [
                ...finbridge,
            ],
        },

    {
        path: '*',
        redirect: i18n.locale + '/error-404',
    },
]
Vue.use(VueRouter)
const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes,
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()
    const userData = getUserData()
    const onlyForAdmin = [
        'kyc-request',
        'kyc-request-view',
    ]
    const onlyForDev = [
        'admin-debug',
    ]
    const withoutLoggedIn = [
        'privacy-policy',
        'terms-of-use',
        'learn-center',
        'landing',
        'auth-register',
    ]

    if (!isLoggedIn && !withoutLoggedIn.includes(to.name)) {
        if (to.name !== "auth-login") {
            return next(`/${i18n.locale}/login`)
        }
        next()
    }

    if (!to.fullPath.includes(`${i18n.locale}`) && to.fullPath.match(['ru|en'])) {
        const locale = to.fullPath.substring(1, 3)

        i18n.locale = locale

        document.cookie = 'lang=' + locale
        next({ path: to.fullPath.replace(/ru|en/, locale) })
    }

    if (!canNavigate(to) && withoutLoggedIn.includes(to.name)) {
        return next()
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    if (onlyForAdmin.includes(to.name)) {
        if (userData.role === 'admin') {
            next()
        } else {
            next('/404-page')
        }
    }
    if (onlyForDev.includes(to.name)) {
        if (process.env.NODE_ENV !== 'production') {
            next()
        } else {
            next('/404-page')
        }
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
